import React from "react";
import { Link } from "react-router-dom";




const WhistleblowingPolicy = () => {

    return (
        <div class="site-content">
            <section className="section-img">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 extra-large">
                            <div className=" headabout">
                                <h1 className="about-heading">Whistleblowing Policy</h1>
                            </div>
                            <p className="our_vission01">This Whistleblowing Policy is developed to provide a structured process for reporting and investigating suspected unethical or illegal activities within RemitAssure. The policy aims to ensure that all employees, contractors, and other stakeholders can report their concerns safely and confidentially, without fear of retaliation.
                            </p>

                            <h3 className="sub-heading">
                                1. Scope
                            </h3>
                            <p className="our_vission01">
                                This policy applies to all employees, contractors, vendors, and any other stakeholders of RemitAssure. It covers the reporting of any misconduct, including but not limited to:
                                <ul>
                                    <li>Fraud
                                    </li>
                                    <li>Corruption
                                    </li>
                                    <li>Financial irregularities
                                    </li>
                                    <li>Harassment or discrimination
                                    </li>
                                    <li>Violations of company policies
                                    </li>
                                    <li>Unsafe working conditions
                                    </li>
                                    <li>Any other illegal or unethical behaviour
                                    </li>
                                </ul>
                            </p>
                            <h3 className="sub-heading">
                                2. Reporting Mechanisms
                            </h3>
                            <p className="our_vission01">
                                Reports of suspected misconduct can be made through the following channels:
                                <ul>
                                    <li>Anonymous Hotline: <a href="tel:+61432252906" target="_blank">+61 432 252 906</a>
                                    </li>
                                    <li>Email: <a href="mailto:whistleblowing@remitassure.com" target="_blank">whistleblowing@remitassure.com</a>
                                    </li>
                                    <li>Direct Reporting: Reports can be made directly to a supervisor, the HR department, or the Compliance Officer.
                                    </li>
                                </ul>
                            </p>
                            <h3 className="sub-heading">
                                3. Confidentiality
                            </h3>
                            <p className="our_vission01">
                                All reports will be handled with the utmost confidentiality to the extent possible, consistent with the need to conduct an adequate investigation. The identity of the whistleblower will not be disclosed without their consent, except as necessary to conduct the investigation or comply with legal requirements.
                            </p>
                            <h3 className="sub-heading">
                                4. Principles
                            </h3>
                            <p className="our_vission01">
                                <ul>
                                    <li><b>Integrity:</b> The whistleblowing process must be used for reporting genuine concerns about illegal or unethical behaviours.
                                    </li>
                                    <li><b>Good Faith:</b> Reports should be made with honest intentions and a reasonable belief that the information provided is accurate and necessary.
                                    </li>
                                    <li><b>Fair Treatment:</b> All reports will be investigated impartially, and any misuse of the whistleblowing process will be addressed appropriately.
                                    </li>
                                </ul>
                            </p>
                            <h3 className="sub-heading">
                                5. No Retaliation
                            </h3>
                            <p className="our_vission01">
                                RemitAssure strictly prohibits retaliation against any individual who reports suspected misconduct in good faith. Retaliation includes any adverse action taken against an employee for reporting a concern, such as termination, demotion, harassment, or any form of discrimination. Any employee who engages in retaliation will be subject to disciplinary action, up to and including termination.
                            </p>

                            <h3 className="sub-heading">
                                6. Investigation Process
                            </h3>
                            <p className="our_vission01">
                                Upon receiving a report, the Compliance Officer or an appointed investigator will:
                                <ul>
                                    <li>Acknowledge receipt of the report to the whistleblower (if not anonymous).
                                    </li>
                                    <li>Conduct a preliminary assessment to determine the credibility of the report.
                                    </li>
                                    <li>If the report is deemed credible, conduct a thorough investigation, which may involve:
                                        <ul>
                                            <li>Reviewing documents and records
                                            </li>
                                            <li>Interviewing relevant parties
                                            </li>
                                            <li>Collaborating with external experts if necessary
                                            </li>
                                            <li>
                                                Maintain documentation of the investigation process and findings.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </p>
                            <h3 className="sub-heading">
                                7. Corrective Actions
                            </h3>
                            <p className="our_vission01">
                                If the investigation confirms misconduct, RemitAssure will take appropriate corrective actions, which may include:
                                <ul>
                                    <li>Disciplinary action against the responsible individuals
                                    </li>
                                    <li>Changes to policies or procedures to prevent future occurrences
                                    </li>
                                    <li>Reporting to relevant authorities if required by law
                                    </li>
                                </ul>
                            </p>
                            <h3 className="sub-heading">
                                8. Communication of Findings
                            </h3>
                            <p className="our_vission01">
                                The whistleblower, if known, will be informed of the investigation's outcome, subject to legal and confidentiality constraints. The findings may also be communicated to relevant stakeholders within the organization as deemed necessary by the Compliance Officer.
                            </p>
                            <h3 className="sub-heading">
                                9. Responsibilities
                            </h3>
                            <p className="our_vission01">
                                <ul>
                                    <li><b>Employees:</b> Responsible for reporting any known or suspected misconduct.
                                    </li>
                                    <li><b>Supervisors/Managers:</b> Responsible for fostering an environment where employees feel comfortable reporting concerns and for forwarding reports to the appropriate channels.
                                    </li>
                                    <li><b>Compliance Officer:</b> Responsible for overseeing the implementation of this policy, conducting investigations, and ensuring corrective actions are taken.
                                    </li>
                                </ul>
                            </p>

                            <h3 className="sub-heading">
                                10. Review and Amendment
                            </h3>
                            <p className="our_vission01">
                                This policy will be reviewed annually by the Compliance Officer and updated as necessary to ensure its effectiveness. Any amendments to the policy will be communicated to all employees and stakeholders.</p>
                            <h3 className="sub-heading">
                                11. Training and Awareness
                            </h3>
                            <p className="our_vission01">
                                RemitAssure will provide training on this Whistleblowing Policy to all employees and relevant stakeholders to ensure they understand their rights and responsibilities. This training will be part of the onboarding process for new employees and will be refreshed periodically.</p>
                            <p className="our_vission01">
                                This Whistleblowing Policy is designed to promote a culture of transparency and accountability at RemitAssure, ensuring that concerns are addressed promptly and fairly, while protecting the rights of those who report misconduct.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default WhistleblowingPolicy; 